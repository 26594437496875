// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-gift-registry-js": () => import("./../../../src/pages/de/gift-registry.js" /* webpackChunkName: "component---src-pages-de-gift-registry-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-information-js": () => import("./../../../src/pages/de/information.js" /* webpackChunkName: "component---src-pages-de-information-js" */),
  "component---src-pages-de-photogallery-js": () => import("./../../../src/pages/de/photogallery.js" /* webpackChunkName: "component---src-pages-de-photogallery-js" */),
  "component---src-pages-de-rsvp-js": () => import("./../../../src/pages/de/rsvp.js" /* webpackChunkName: "component---src-pages-de-rsvp-js" */),
  "component---src-pages-en-gift-registry-js": () => import("./../../../src/pages/en/gift-registry.js" /* webpackChunkName: "component---src-pages-en-gift-registry-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-information-js": () => import("./../../../src/pages/en/information.js" /* webpackChunkName: "component---src-pages-en-information-js" */),
  "component---src-pages-en-photogallery-js": () => import("./../../../src/pages/en/photogallery.js" /* webpackChunkName: "component---src-pages-en-photogallery-js" */),
  "component---src-pages-en-rsvp-js": () => import("./../../../src/pages/en/rsvp.js" /* webpackChunkName: "component---src-pages-en-rsvp-js" */),
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-hr-gift-registry-js": () => import("./../../../src/pages/hr/gift-registry.js" /* webpackChunkName: "component---src-pages-hr-gift-registry-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-information-js": () => import("./../../../src/pages/hr/information.js" /* webpackChunkName: "component---src-pages-hr-information-js" */),
  "component---src-pages-hr-photogallery-js": () => import("./../../../src/pages/hr/photogallery.js" /* webpackChunkName: "component---src-pages-hr-photogallery-js" */),
  "component---src-pages-hr-rsvp-js": () => import("./../../../src/pages/hr/rsvp.js" /* webpackChunkName: "component---src-pages-hr-rsvp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informace-js": () => import("./../../../src/pages/informace.js" /* webpackChunkName: "component---src-pages-informace-js" */),
  "component---src-pages-pl-galeria-zdjec-js": () => import("./../../../src/pages/pl/galeria-zdjęc.js" /* webpackChunkName: "component---src-pages-pl-galeria-zdjec-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-informacja-js": () => import("./../../../src/pages/pl/informacja.js" /* webpackChunkName: "component---src-pages-pl-informacja-js" */),
  "component---src-pages-pl-potwierdz-udzial-js": () => import("./../../../src/pages/pl/potwierdz-udział.js" /* webpackChunkName: "component---src-pages-pl-potwierdz-udzial-js" */),
  "component---src-pages-potvrdit-ucast-js": () => import("./../../../src/pages/potvrdit-ucast.js" /* webpackChunkName: "component---src-pages-potvrdit-ucast-js" */),
  "component---src-pages-svatebni-dary-js": () => import("./../../../src/pages/svatebni-dary.js" /* webpackChunkName: "component---src-pages-svatebni-dary-js" */)
}

